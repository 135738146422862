import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Container, Divider } from "@mui/material";
import { motion } from "framer-motion"; // Import motion from framer-motion
import GuineaPig1 from "../../data/logo/GuineaPig1.jpeg";

export default function Pig() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }} // Initial animation properties
      animate={{ opacity: 1, y: 0 }} // Animation when the component appears
      exit={{ opacity: 0, y: -100 }} // Animation when the component disappears
      transition={{ duration: 1 }} // Animation duration
    >
      <Container
        sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}
      >
        <Card sx={{ width: 500, padding: "10px" }}>
          <CardMedia
            sx={{ height: 200 }}
            image={GuineaPig1}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              GUINEA PIG - DUNKIN HARTLEY
            </Typography>

            <Typography variant="body2" color="text.secondary">
              Best study model for – <br />• Immunological studies
              <br />• Skin Sensitization <br />• Allergic studies
              <br />• Multipurpose and surgical models
              <br />• Antibody Production
            </Typography>
            <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
          </CardContent>
        </Card>
      </Container>
    </motion.div>
  );
}
