import React from "react";
import { Grid, Card, CardContent, Typography, Container } from "@mui/material";

const companyData = [
  {
    name: "Prado Preclinical Research Pvt. Ltd.",
  },
  {
    name: "Intox Pvt. Ltd.",
  },
  {
    name: "Haffkine Institute and Haffkine Biopharmaceuticals",
  },
  {
    name: "Bharat Serums and Vaccines Pvt. Ltd.",
  },
  {
    name: "Sinhgad Institutes",
  },
  {
    name: "NMIMS, Mumbai",
  },
  {
    name: "Savitribai Phule Pune University, Pune",
  },
  {
    name: "Ribosome Research center pvt ltd.Gujrat",
  },
  {
    name: "Bombay College of Pharmacy, Mumbai",
  },
  {
    name: "Tox India Pvt. Ltd, Pune",
  },
  {
    name: "Indian Institute of Toxicology, Hadapsar",
  },
  {
    name: "Sa- Ford, Taloja",
  },
  {
    name: "AND MANY MORE IN PAN INDIA",
  },
];

const ServicesPage = () => {
  return (
    <Container className="Service component_space" id="Services">
      <h2 style={{ textAlign: "center", color: "red" }}>Our Clients</h2>
      <Grid container spacing={3}>
        {companyData.map((company, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className="service_box pointer" style={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <b>{company.name}</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {company.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesPage;
