import React from "react";

const GoogleMapsLocation = () => {
  return (
    <div className="map-container">
      <iframe
        title="Google Maps Location"
        //  lat 18.402540,
        // log   74.541318
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.526722488142!2d73.9249425!3d18.2134562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2f3f8062bbf93%3A0xc8131d604973b3e6!2sNational%20Institute%20of%20Biosciences!5e0!3m2!1sen!2sin!4v1632261726519!5m2!1sen!2sin"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};

export default GoogleMapsLocation;

// import React, { useEffect, useRef } from "react";

// const GoogleMapsLocation = ({ latitude, longitude }) => {
//   const mapContainerRef = useRef(null);

//   useEffect(() => {
//     // Fetch the location based on latitude and longitude
//     const mapOptions = {
//       center: { lat: latitude, lng: longitude },
//       zoom: 14,
//     };

//     // Create a new Google Map and attach it to the map container element
//     new window.google.maps.Map(mapContainerRef.current, mapOptions);
//   }, [latitude, longitude]);

//   return (
//     <div ref={mapContainerRef} style={{ width: "100%", height: "450px" }} />
//   );
// };

// export default GoogleMapsLocation;
// <GoogleMapsLocation latitude={18.40254} longitude={74.541318} />;
