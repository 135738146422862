import React from "react";
import { Container, Typography, Paper, Grid } from "@mui/material";
// import logo from "./logo/logo.png";
import Box from "@mui/material/Box";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import { IconButton } from "@mui/material";
import AboutUs from "../About/About";

const About = () => {
  return (
    // <Box>
    //   {/* title */}
    //   <Typography
    //     variant="h3"
    //     style={{ marginTop: "16px", textAlign: "center" }}
    //   >
    //     NUTRIVET LIFE SCIENCES
    //   </Typography>
    //   <hr
    //     style={{
    //       borderTop: "2px solid red",
    //       width: "20%",
    //       margin: "16px auto",
    //     }}
    //   />

    //   {/* Text content in a Grid */}
    //   <Container fixed style={{ backgroundColor: "pink" }}>
    //     <Grid
    //       container
    //       style={{ marginTop: "16px", padding: "15px" }}
    //       spacing={3}
    //     >
    //       <p>
    //         <b>
    //           LABORATORY ANIMAL DIETS AND MANUFACTURING AND SUPPLIER AN ISO 9001
    //           : 2015 CERTIFIED COMPANY
    //         </b>
    //       </p>
    //       <p>
    //         NUTRIVET LIFE SCIENCES is Established in the year 2017 at Pune,
    //         Maharashtra, We "Nutrivet Life Sciences" are as Sole Proprietorship
    //         based firm, engaged as the foremost Authorized Manufacturer and
    //         Supplier of Laboratory Animal Diets, Animal Pellet Feed and Animal
    //         Bedding Material.
    //       </p>

    //       <p>
    //         Our Speciality - Considering importance of laboratory Toxicology
    //         studies and Researches, we Claim That We Are the Only Company Making
    //         Laboratory Animal Feed Dedicatedly in Dedicated Plant located around
    //         90 Kms from Pune in Own premises having better connectivity of
    //         Infrastructure and logistics.
    //       </p>

    //       <p>
    //         Natural, Best Quality Grains, Raw Material is used to make feed
    //         pellets. Balanced Diets for constant Nutrition. Fortified with
    //         required Amino acids, Vitamins and Minerals. Purely Vegetarian
    //         Diets, No Animal Protein and Alfalfa. Superior Quality Raw Material
    //         Selection and regular analysis at Third Party Labs for Quality
    //         Assurance. Packaging of Pelleted feed is done in Double Layered
    //         Paper HDPE Sacs.
    //       </p>
    //     </Grid>
    //   </Container>

    //   <Container>
    //     <Grid container style={{ marginTop: "16px" }} spacing={2}>
    //       <Grid item xs={12} md={4}>
    //         <h3 style={{ textAlign: "center" }}>Mission</h3>
    //         <p>
    //           With the operation facilities located south of Maharashtra in
    //           Sangli (240kms from Pune), KVAT employees over 100 blue collar
    //           employees.
    //         </p>
    //       </Grid>

    //       <Grid item xs={12} md={4}>
    //         <img
    //           src="https://tse4.mm.bing.net/th?id=OIP.GshhbMJsX_pdShJOBoxEfQHaFj&pid=Api&P=0&h=180"
    //           style={{ width: "100%", height: "auto", borderRadius: "100%" }}
    //         />
    //       </Grid>

    //       <Grid item xs={12} md={4}>
    //         <h3 style={{ textAlign: "center" }}>Vision</h3>
    //         <p>
    //           With the operation facilities located south of Maharashtra in
    //           Sangli (240kms from Pune), KVAT employees over 100 blue collar
    //           employees.
    //         </p>
    //       </Grid>
    //     </Grid>
    //   </Container>
    // </Box>
    <>
      <AboutUs />
    </>
  );
};

export default About;
