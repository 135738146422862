import { useNavigate } from "react-router-dom";
import MenuCard from "./MenuCard";
import Rate2 from "../../data/logo/RatSD2.jpeg";
import Mousec571 from "../../data/logo/MouseSA1.jpeg";
import rabbit2 from "../../data/logo/rabbit2.jpeg";
import GuineaPig1 from "../../data/logo/GuineaPig1.jpeg";

import Styles from "./MenuCard.css";

const Menu = () => {
  const navigate = useNavigate();

  const navigateItemOne = () => {
    navigate("/rat");
  };
  const navigateItemTwo = () => {
    navigate("/mouse");
  };
  const navigateItemThree = () => {
    navigate("/rabbit");
  };
  const navigateItemFour = () => {
    navigate("/pig");
  };
  return (
    <section id="menu">
      <h1>AVAILABLE STRAINS AND SPECIES.</h1>
      <div>
        <MenuCard
          itemNum={1}
          burgerSrc={Rate2}
          title="RAT"
          delay={0.1}
          handler={navigateItemOne}
        />
        <MenuCard
          itemNum={2}
          burgerSrc={Mousec571}
          title="MOUSE"
          delay={0.5}
          handler={navigateItemTwo}
        />
        <MenuCard
          itemNum={3}
          burgerSrc={rabbit2}
          title="RABBIT"
          delay={0.8}
          handler={navigateItemThree}
        />
        <MenuCard
          itemNum={4}
          burgerSrc={GuineaPig1}
          title="GUINEA PIG"
          delay={1}
          handler={navigateItemFour}
        />
      </div>
    </section>
  );
};

export default Menu;
