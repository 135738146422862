// import * as React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MobileStepper from "@mui/material/MobileStepper";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
// import images from "../../data/homedata/Images";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// function Home() {
//   const theme = useTheme();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const maxSteps = images.length;

//   const handleStepChange = (step) => {
//     setActiveStep(step);
//   };

//   return (
//     <Box sx={{ maxWidth: "100vw", flexGrow: 1 }}>
//       <Paper
//         square
//         elevation={0}
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           height: 50,
//           pl: 2,
//           bgcolor: "background.default",
//         }}
//       >
//         <Typography>{images[activeStep].label}</Typography>
//       </Paper>
//       <AutoPlaySwipeableViews
//         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//         index={activeStep}
//         onChangeIndex={handleStepChange}
//         enableMouseEvents
//       >
//         {images.map((step, index) => (
//           <div key={step.label}>
//             {Math.abs(activeStep - index) <= 2 ? (
//               <Box
//                 component="img"
//                 sx={{
//                   height: "80vh",
//                   display: "block",
//                   overflow: "hidden",
//                   width: "100vw",
//                 }}
//                 src={step.imgPath}
//                 alt={step.label}
//               />
//             ) : null}
//           </div>
//         ))}
//       </AutoPlaySwipeableViews>
//       <MobileStepper
//         steps={maxSteps}
//         position="static"
//         activeStep={activeStep}
//       />
//     </Box>
//   );
// }

// export default Home;

//!

import React from "react";

import { useNavigate } from "react-router-dom";
import Menu from "../home/Menu";
import ServicesPage from "../company/ServicesPage";

const HomePage = () => {
  const navigate = useNavigate();

  const navigateToServices = () => {
    navigate("/products");
  };

  return (
    <div className="position-relative">
      <Menu />
      <div style={{ marginBottom: "10px" }}>
        <ServicesPage />
      </div>
    </div>
  );
};

export default HomePage;
