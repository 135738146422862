import React from "react";
import { Container, Divider, Grid } from "@mui/material";
import Rate2 from "../../data/logo/RatSD2.jpeg";
import GuineaPig1 from "../../data/logo/GuineaPig1.jpeg";

//  import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <img src={Rate2} style={{ height: "300px", width: "400px" }} />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginLeft: "200px" }}>
            <h3 className="One" style={{ color: "red" }}>
              About Us
            </h3>
            <p className="text">
              <b>
                NATIONAL INSTITUTE OF BIOSCIENCES CPCSEA APPROVED LABORATORY
                ANIMAL BREEDER AND SUPPLIER CPCSEA REG. NO.
                1091/GO/Bt/S/07/CPCSEA
              </b>
              <br />
              <Divider sx={{ padding: "10px" }} />
              National Institute of Biosciences is CPCSEA registered breeder and
              supplier of Laboratory Animals since 2007. National Institute of
              Biosciences is reliable supplier of quality small laboratory
              animals.
            </p>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <p className="text">
              <b style={{ color: "red" }}>OUR VISION </b>
              <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
              <p>
                National Institute of Biosciences aims to be the market-leader
                in Quality Animal Breeding and Supply for its clients and
                Researchers through technical Excellence.
              </p>
              <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
              <b style={{ color: "red" }}>OUR MISSION </b>
              <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
              <p>
                To become the most effective, integrated, quality driven Service
                provider for Quality animal supply for its clients and
                Researchers.
              </p>
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={GuineaPig1}
              style={{ height: "300px", width: "300px", marginLeft: "100px" }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
