import React from "react";

import Home from "../../features/frontend/content/Home";
import About from "../../features/frontend/content/About";
import Conatact from "../../features/frontend/content/Contact";
import Rat from "../../features/frontend/Animal/Rat";
import Mouse from "../../features/frontend/Animal/Mouse";
import Pig from "../../features/frontend/Animal/Pig";
import Rabbit from "../../features/frontend/Animal/Rabbit";
import Facilities from "../../features/frontend/content/Services";
import Udyam from "../../features/frontend/content/Udyam";

export default [
  {
    label: "Home",
    path: "",
    showInMenu: true,
    addRoute: true,
    component: <Home />,
    authenticated: "no",
  },
  {
    label: "Facility ",
    path: "facility",
    showInMenu: true,
    addRoute: true,
    component: <Facilities />,
    authenticated: "no",
  },
  {
    label: "About",
    path: "about",
    showInMenu: true,
    addRoute: true,
    component: <About />,
    authenticated: "no",
  },
  {
    label: "Contact",
    path: "contact",
    showInMenu: true,
    addRoute: true,
    component: <Conatact />,
    authenticated: "no",
  },
  {
    // label: "Pdf",
    path: "rat",
    showInMenu: false,
    addRoute: true,
    component: <Rat />,
    authenticated: "no",
  },
  {
    // label: "Gust",
    path: "mouse",
    showInMenu: false,
    addRoute: true,
    component: <Mouse />,
    authenticated: "no",
  },
  {
    // label: "udyam",
    path: "pig",
    showInMenu: false,
    addRoute: true,
    component: <Pig />,
    authenticated: "no",
  },
  {
    // label: "udyam",
    path: "rabbit",
    showInMenu: false,
    addRoute: true,
    component: <Rabbit />,
    authenticated: "no",
  },
  {
    // label: "udyam",
    path: "udyam",
    showInMenu: false,
    addRoute: true,
    component: <Udyam />,
    authenticated: "no",
  },
];
