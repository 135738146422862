import React, { useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import emailjs from "emailjs-com"; // Updated import statement
import { styled } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion"; // Import from framer-motion
import GoogleMapsLocation from "../../GoogleMapsLocation";

const Contact = () => {
  const EnqLink = styled(NavLink)({
    textDecoration: "none",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    padding: "12px 0",
    backgroundColor: "#ed563b",
    width: "100%",
    fontSize: "18px",
    transition: "all 0.3s",
    textAlign: "center",
    margin: "10px 0px",
    "&:hover": {
      backgroundColor: "#f9735b",
    },
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_o36s1ru",
        "template_mhca2f2",
        form.current,
        "18zrPEDOJ_xcfhZYL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
  };

  const [contactUs, setContactUs] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactUs({ ...contactUs, [name]: value });
  };

  return (
    <Box>
      <Box
        sx={{
          color: "#ed563b",
          fontWeight: "700",
          letterSpacing: "2.5px",
          fontSize: 40,
          p: 4,
        }}
        align="center"
      ></Box>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Box
          mx="auto"
          borderRadius="10px"
          sx={{
            boxShadow: "5px 5px 25px rgba(0,0,0,0.2)",
            width: { lg: "80%" },
            padding: "20px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            backgroundColor: "#232d39",
          }}
        >
          <Box
            marginTop="50px"
            sx={{
              width: {
                md: "50%",
                xs: "100%",
                color: "white",
              },
            }}
          >
            <h2 align="center">
              Dr. SATISH DATTAJI BHANDE
              <div style={{ color: "#ed563b" }}>Proprietor.</div>
            </h2>

            <Box>
              <Typography marginLeft="10px" align="center" padding="20px">
                Call Us -
                <div>
                  <a
                    href="tel:+918080212801"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {" "}
                    +91 8080212801
                  </a>
                  {"  "} , {"  "}
                  <a
                    href="tel:+919822006765"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    +91 9822006765
                  </a>
                </div>
                <div></div>
              </Typography>
            </Box>
            <Box>
              <Typography marginLeft="10px" align="center" padding="20px">
                Email Us - <br />
                <a
                  href="mailto:marketing.nibslab@gmail.com?body=Dear, National Institute Of Biosciences"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  marketing.nibslab@gmail.com
                </a>
                <br />
                <a
                  href="mailto:contact@nibpune.in?body=Dear, National Institute Of Biosciences"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  contact@nibpune.in
                </a>
                <br />
                <a
                  href="mailto:sales@nibpune.in?body=Dear, National Institute Of Biosciences"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  sales@nibpune.in
                </a>
              </Typography>
            </Box>
            <Box>
              <Typography marginLeft="10px" align="center" padding="20px">
                Registered / Office Address - <br />
                14, Ajay Apartment, Manikbaug, Sinhgad Road Pune. 411051.
              </Typography>
            </Box>
            <Box>
              <Typography marginLeft="10px" align="center" padding="20px">
                Facility Address - <br />
                Sr. No. 69, AT/Post: Dhangawadi, Off Pune Banglore Highway, Tal:
                Bhor Dist: Pune
              </Typography>
            </Box>
          </Box>
          <Card
            sx={{
              maxWidth: 450,
              marginLeft: "5px",
              padding: "5px 5px",
            }}
          >
            <CardContent>
              <Typography gutterBottom varient="h4" color="#ed563b">
                Get in touch with us:
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                varient="body2"
                component="p"
              >
                Fill up the form and our team will get back to you in 24 hours.
              </Typography>

              <form ref={form} onSubmit={sendEmail}>
                <Grid container spacing={1} sx={{ color: "white" }}>
                  <Grid xs={12} item>
                    <TextField
                      label="Name"
                      placeholder="name"
                      variant="outlined"
                      name="name"
                      value={contactUs.name}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <TextField
                      type="email"
                      label="Email"
                      placeholder="Enter email"
                      variant="outlined"
                      name="email"
                      value={contactUs.email}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextField
                      label="Message"
                      multiline
                      rows={4}
                      placeholder="Enter message"
                      variant="outlined"
                      name="message"
                      value={contactUs.message}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} item sx={{ display: "flex" }}>
                    <Button type="submit" variant="contained" color="primary">
                      Send Enquiry
                      <SendIcon sx={{ margin: "0 auto", color: "white" }} />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div style={{ margin: "20px" }}>
          <GoogleMapsLocation />
        </div>
      </motion.div>
    </Box>
  );
};

export default Contact;
