import "./FooterStyles.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="top">
          <div>
            <h1>NATIONAL INSTITUTE OF BIOSCIENCES</h1>
            <p> Welcome To Our Site</p>
          </div>
        </div>

        <div className="bottom">
          <div>
            <h4>Registered office</h4>
            <a href="/">
              14, AJAY APARTMENT, MANIKBAUG,
              <br /> SINHGAD ROAD, PUNE. 411051
            </a>
            <h4>Facility Office</h4>
            <a href="/">
              Sr. No. 69, AT/Post: Dhangawadi,
              <br />
              Off Pune Banglore Highway, Tal: Bhor Dist: Pune
            </a>
          </div>
          <div>
            <h4> Contact numbers</h4>
            <a
              href="tel:+918080212801"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              +91 8080212801
            </a>
            <a
              href="tel:+919822006765"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              +91 9822006765
            </a>
          </div>
          <div>
            <h4>OUR CERTIFICATIONS</h4>
            <a href=""> ISO</a>
            <a href="/udyam"> UDYAM</a>
            <a href=""> GST</a>
          </div>
          <div>
            <h4> Others Contact</h4>
            <a
              href="mailto:marketing.nibslab@gmail.com?body=Dear, National Institute Of Biosciences"
              style={{ color: "white", textDecoration: "none" }}
            >
              mailto:marketing.nibslab@gmail.com
            </a>
            <a
              href="mailto:contact@nibpune.in?body=Dear, National Institute Of Biosciences"
              style={{ color: "white", textDecoration: "none" }}
            >
              mailto:contact@nibpune.in
            </a>
            <a
              href="mailto:sales@nibpune.in?body=Dear, National Institute Of Biosciences"
              style={{ color: "white", textDecoration: "none" }}
            >
              mailto:sales@nibpune.in
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
