import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import udyamImag from "../../data/logo/uuuuu.jpg";
import logo from "../../data/logo/logo.png";

const Udyam = () => {
  return (
    <Container maxWidth="md">
      <Card>
        <CardMedia
          component="img"
          alt="Sample Image"
          height="auto"
          image={udyamImag} // Replace with your image URL
        />
      </Card>
    </Container>
  );
};

export default Udyam;
