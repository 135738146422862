import React from "react";
import Header from "./Header";
import Footer from "../../features/frontend/content/Footer";
import logo from "../../features/data/logo/logo.png";
import { Grid } from "@mui/material";

const BlankLayout = () => {
  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={logo}
          alt=""
          style={{
            width: "10%",
            maxHeight: "500px",
            marginTop: "70px",
            // marginLeft: "600px",
            marginTop: "100px",
          }}
        />
      </Grid>
      <Grid>
        <h1 style={{ textAlign: "center" }}>
          <b style={{ color: "red" }}>
            {" "}
            NATIONAL INSTITUTE OF BIOSCIENCES <br />
          </b>{" "}
          CPCSEA APPROVED LABORATORY ANIMAL BREEDER AND SUPPLIER
          <br /> CPCSEA REG. NO. 1091/GO/Bt/S/07/CPCSEA
        </h1>
      </Grid>
      <Header />
      <Footer />
    </>
  );
};

export default BlankLayout;
