import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Container, Divider } from "@mui/material";
import { motion } from "framer-motion"; // Import motion from framer-motion
import Rabbit1 from "../../data/logo/Rabbit1.jpeg";

export default function Rabbit() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }} // Initial animation properties
      animate={{ opacity: 1, y: 0 }} // Animation when the component appears
      exit={{ opacity: 0, y: -100 }} // Animation when the component disappears
      transition={{ duration: 1 }} // Animation duration
    >
      <Container
        sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}
      >
        <Card sx={{ width: 500, padding: "10px" }}>
          <CardMedia
            sx={{ height: 400 }}
            image={Rabbit1}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              RABBIT - NEW ZEALAND WHITE
            </Typography>

            <Typography variant="body2" color="text.secondary">
              Best study model for – <br />• Dermatology studies
              <br />• Ophthalmology Studies <br />• • Antibody Productional
              Studies
            </Typography>
            <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
          </CardContent>
        </Card>
      </Container>
    </motion.div>
  );
}
