import React from "react";
import { Container, Divider, Grid } from "@mui/material";
import one from "../../data/logo/one.png";
import two from "../../data/logo/two.png";
import three from "../../data/logo/three.png";

const Facilities = () => {
  return (
    <Container>
      <h1 style={{ textAlign: "center", color: "red" }}>
        Animal House Facility{" "}
      </h1>
      <p>
        <b>Animal Rooms: </b>
        <br />
        Air Supply: HVAC with 100% fresh air.
        <br /> Flooring: Epoxy. <br />
        Wall Paint: PU paint Doors: GMP doors
      </p>
      <Divider sx={{ padding: "10px", marginBottom: "10px" }} />

      <p>
        <b>Sterilization:</b>
        <br /> Cages, bottles, feed and bedding material is sterilized by
        autoclaving. <br />
        <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
        <b>Pest Control: </b>outsourced with PCI Health Monitoring:
        <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
        <br /> <b>Parasitological examination:</b> Carried out every three
        months
        <br />
        <Divider sx={{ padding: "10px", marginBottom: "10px" }} />
        <b> Hematology and Biochemistry:</b> Carried out every six months.
      </p>
      <Grid
        Container
        sx={{
          mt: 10,
          "@media (max-width: 600px)": {
            display: "block",
          },
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Grid item xs={12} md={4}>
          <img
            src={one}
            style={{ width: "100%", height: 300, objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={two}
            style={{ width: "100%", height: 300, objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={three}
            style={{ width: "100%", height: 300, objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Facilities;
