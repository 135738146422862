import React from "react";
import { motion } from "framer-motion";

const MenuCard = ({ itemNum, burgerSrc, price, title, handler, delay = 0 }) => {
  return (
    <motion.div
      className="menuCard"
      initial={{ x: "-100%", opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{
        delay,
      }}
    >
      <main>
        <img
          src={burgerSrc}
          alt={itemNum}
          style={{ height: "200px", width: "200px" }}
        />
        <h5>{price}</h5>
        <p style={{ fontSize: "24px" }}>
          <b>{title}</b>
        </p>
        <button onClick={() => handler(itemNum)}>View</button>
      </main>
    </motion.div>
  );
};

export default MenuCard;
