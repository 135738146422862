import * as React from "react";
import "./App.css";
import BlankLayout from "./layouts/blanklayout/BlankLayout";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<BlankLayout />} />
    </Routes>
  );
}

export default App;
