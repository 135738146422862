import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion"; // Import motion from framer-motion
import Mousec571 from "../../data/logo/Mousec571.jpeg";
import MouseSA1 from "../../data/logo/MouseSA1.jpeg";
import Mousec572 from "../../data/logo/Mousesa2.jpeg";

export default function Mouse() {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
        alignItems: "center",
        "@media (max-width: 600px)": {
          display: "block", // Change to a block layout
        },
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 1 }}
        style={{ display: "flex" }}
      >
        <Grid container spacing={2}>
          {/* For small devices, stack the cards vertically */}
          <Grid item xs={12}>
            <Card sx={{ padding: "10px" }}>
              <CardMedia
                sx={{ height: 200 }}
                image={Mousec571}
                title="C57BL/6 or C57 Black 6"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <h4> C57BL/6 or C57 Black 6</h4>
                  Best study model for – <br />• Safety / Efficacy Study
                  <br />• Diabetic study
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: "10px" }}>
              <CardMedia
                sx={{ height: 200 }}
                image={MouseSA1}
                title="SWISS ALBINO MICE"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <h4>SWISS ALBINO MICE</h4>
                  Best study model for – <br />• Safety / Efficacy Study
                  <br />• Toxicological study
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: "10px" }}>
              <CardMedia
                sx={{ height: 200 }}
                image={Mousec572}
                title="BALB/c"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <h4>BALB/c </h4>
                  Best study model for – <br />• Monoclonal Antibody production
                  <br />• Cancer therapy studies <br />• Immunological studies
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </motion.div>
    </Container>
  );
}
