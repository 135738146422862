import React from "react";
import { motion } from "framer-motion";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Container, Divider, Grid } from "@mui/material";
import Rate from "../../data/logo/ratsd3.jpeg";
import RatWistar1 from "../../data/logo/RatWistar1.jpeg";

const variants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Rat = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        sx={{ width: 500, padding: "10px" }}
      >
        <Grid item xs={12} md={6}>
          <Card sx={{ width: "400px", mr: 5 }}>
            <CardMedia sx={{ height: 400 }} image={Rate} title="green iguana" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <b> SPRAGUE DAWLEY</b>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Best study model for – <br />• Safety / Efficacy Study
                <br /> • Toxicological study <br /> • etc
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        sx={{ width: 500, padding: "10px" }}
      >
        <Grid item xs={12} md={6}>
          <Card sx={{ width: "400px" }}>
            <CardMedia
              sx={{ height: 400 }}
              image={RatWistar1}
              title="green iguana"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <b> WISTAR</b>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Best study model for – <br />• Safety / Efficacy pharmacology
                Study
                <br />• Toxicological study <br />• Cardiotoxicity Study
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </motion.div>
    </Container>
  );
};

export default Rat;
